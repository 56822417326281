export default {
    SERVER: 'https://test.dtc121.ro/api/appointments',
    // SERVER: 'https://live.dtc121.ro/api/appointments',
    // SERVER: 'https://dev2022a.dtc121.ro/api/appointments',
    LOGIN: '/login',
    GET_FREE_SLOTS: '/get-appointment-free-slots',
    SET_APPOINTMENT: '/set-contact-appointment',

    GET_CONSUMER_DATA: '/get-interaction-data',
    SET_CONSUMER_DATA: '/set-consumer-app-data'        
};